import {
  Pagination,
  PaginationButton,
  PaginationEllipses,
  PaginationStep,
} from '@vp/swan'
import { ReviewsPagination } from '../types'
import { trackEvent } from '../utils/tracking'

interface IPaginationContainer {
  pagination: ReviewsPagination
  selectPage: (arg0: number) => void
}
export const PaginationContainer: React.FC<IPaginationContainer> = ({
  pagination,
  selectPage,
}) => {
  const trackPagination = (pageNumber: number) => {
    trackEvent({
      eventName: 'Ratings Reviews Engaged',
      properties: {
        label: 'Pagination Used',
        eventDetail: `${pagination.currentPageNumber}:${pageNumber}`,
      },
    })
  }

  return (
    <Pagination accessibleText={pagination.paginationLabel}>
      <PaginationButton
        variant='previous'
        as='button'
        accessibleText={pagination.previous}
        disabled={pagination.currentPageNumber === 1}
        onClick={() => {
          const pageNumber = pagination.currentPageNumber - 1
          selectPage(pagination.currentPageNumber - 1)
          trackPagination(pageNumber)
        }}
      />
      {Array.from({ length: 5 }, (_, index) => {
        const page = index + pagination.currentPageNumber
        return (
          <PaginationStep
            key={page}
            as='button'
            accessibleText={`${pagination.paginationInputLabel} ${page}`}
            active={page === pagination.currentPageNumber}
            onClick={() => {
              selectPage(page)
              trackPagination(page)
            }}
          >
            {page}
          </PaginationStep>
        )
      })}
      <PaginationEllipses />

      <PaginationButton
        variant='next'
        as='button'
        accessibleText={pagination.next}
        onClick={() => {
          const pageNumber = pagination.currentPageNumber + 1
          selectPage(pageNumber)
          trackPagination(pageNumber)
        }}
      />
    </Pagination>
  )
}
