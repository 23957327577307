import {
  BasicResponsiveImage,
  Box,
  Card,
  Carousel,
  CarouselSlide,
  responsive,
  Typography,
} from '@vp/swan'
import { useEffect, useMemo, useState } from 'react'

import { ReviewData } from '../../types'

import { useUserContext } from '@vp/ubik-context'
import { ImageModal } from '../Modal'
import { getReviewsWithImages } from '../../utils/getReviewsWithImages'
import { trackEvent } from '../../utils/tracking'
import { useI18n } from '../../localization/LanguageContext'

const ResponsiveCarousel = responsive(Carousel)

export const ImageCarousel = () => {
  const { locale, productId: mpvId } =
    useUserContext()

  const { translate }= useI18n()
  const [isOpen, setIsOpen] = useState(false)
  const [reviewsData, setReviewsData] = useState<ReviewData>()
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const initialize = async () => {
      if (mpvId && locale) {
        const data = await getReviewsWithImages(mpvId, locale)
        if (data) {
          setReviewsData(data)
        }
      }
    }
    initialize()
  }, [])

  const { reviews, texts } = reviewsData ?? {}

  const media = useMemo(() => {
    if (!reviews || !Array.isArray(reviews)) return [] // Ensure reviews is an array

    return reviews.flatMap((review) => {
      if (!review.images || !Array.isArray(review.images)) return [] // Ensure images exist

      return review.images.map((image) => ({
        ...image,
        reviewId: review.id ?? 0, // Fallback to 0 if id is missing
      }))
    })
  }, [reviews])

  const carouselBeforeChange = async (size: string, index: number) => {
    const threshold = size === 'xs' ? 1 : size === 'sm' ? 3 : 6
    if (index + threshold >= media.length / threshold) {

      // while api call in progress stop carousel click
      const data = await getReviewsWithImages(mpvId!, locale, 10, media.length)
      if (data) {
        if (reviewsData) {
          data.reviews = [...reviewsData.reviews, ...data.reviews]
        }
        setReviewsData(data)
      }
    }
  }

  // received from modal - Commenting below lines because we don't have to fetch reviews when the active index is changed
  // useEffect(() => {
  //   activeIndex && carouselBeforeChange('xs', activeIndex)
  // }, [activeIndex])

  if (!reviews) return <></>

  return (
    <>
      <Box
        style={{
          zIndex: 0,
          position: "relative"
        }}
      >
        <Typography fontSkin='title-section' mb='between-subsections' as='h3'>
          {translate('review-images-title')}
        </Typography>
        <ResponsiveCarousel
          skin="full"
          slidesToShow={{ xs: 2.45, md: 6.45 }}
          gridGutters
          infinite={false}
          xs={{
            slidesToScroll: 1,
            beforeChange: (index) => {
              carouselBeforeChange('xs', index)
            },
          }}
          md={{
            slidesToScroll: 6,
            beforeChange: (index) => {
              carouselBeforeChange('md', index)
            },
          }}
          sm={{
            slidesToScroll: 3,
            beforeChange: (index) => {
              carouselBeforeChange('sm', index)
            },
          }}
          accessibleTextForPrevious={translate('previous-slide-label')}
          accessibleTextForNext={translate('next-slide-label')}
          afterChange={() => {
            trackEvent({
              eventName: 'Ratings Reviews Gallery Engaged',
              properties: { label: 'More Image Loaded' },
            })
          }}
        >
          {media.map((media, index: number) => {
            return (
              <CarouselSlide key={media.id}>
                <Card fullBleed overflow='hidden'>
                  <BasicResponsiveImage
                    role='button'
                    src={media.src}
                    alt={media.headline}
                    style={{ cursor: 'pointer' }}
                    aspectRatio={1}
                    onClick={() => {
                      setIsOpen(true)
                      setActiveIndex(index)
                      trackEvent({
                        eventName: 'Ratings Reviews Gallery Engaged',
                        properties: { label: 'Image Opened' },
                      })
                    }}
                  />
                </Card>
              </CarouselSlide>
            )
          })}
        </ResponsiveCarousel>
      </Box>
      <ImageModal
        isOpen={isOpen}
        reviews={reviews}
        media={media}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        texts={texts}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
