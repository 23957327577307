import React, { createContext, useContext, useState } from 'react'
import { Locale } from '../types'
import { getI18nInstance } from './translations';

interface I18nContextType {
    locale: Locale;
    translate: (key: string) => string;
    setLocale: (locale: Locale) => void;
}

const I18nContext = createContext<I18nContextType | undefined>(undefined)

export const I18nProvider: React.FC<{ defaultLocale?: Locale; children: React.ReactNode }> = ({
    defaultLocale = Locale.enIE,
    children,
}) => {
    const [locale, setLocaleState] = useState<Locale>(defaultLocale)
    const i18n = getI18nInstance(locale)

    const setLocale = (newLocale: Locale) => {
        setLocaleState(newLocale)
    }

    return (
        <I18nContext.Provider value={{ locale, translate: i18n.translate.bind(i18n), setLocale }}>
            {children}
        </I18nContext.Provider>
    )
}

export const useI18n = (): I18nContextType => {
    const context = useContext(I18nContext)
    if (!context) {
        throw new Error('useI18n must be used within an I18nProvider')
    }
    return context
}
