import {
  BasicResponsiveImage,
  Carousel,
  CarouselSlide,
  Column,
  GridContainer,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogNav,
  responsive,
  Row,
} from '@vp/swan'
import { ReviewListItem } from '../List/Item'
import { ReviewItem } from '../../types'
import { useEffect, useState } from 'react'
import { trackEvent } from '../../utils/tracking'
import { useI18n } from '../../localization/LanguageContext'

interface IImageModal {
  reviews: ReviewItem[]
  texts?: Record<string, any>
  isOpen: boolean
  activeIndex: number
  setIsOpen: (arg0: boolean) => void
  setActiveIndex: (arg0: number) => void
  media: Array<ReviewItem['images'][number] & { reviewId: number }>
}
const ResponsiveModalContent = responsive(ModalDialogContent)

export const ImageModal: React.FC<IImageModal> = ({
  reviews,
  texts,
  isOpen,
  setIsOpen,
  activeIndex,
  setActiveIndex,
  media,
}) => {

  const { translate } = useI18n()
  const [currentSlide, setCurrentSlide] = useState(activeIndex)

  // important: as current slide changes internally plus based on activeIndex
  useEffect(() => {
    setCurrentSlide(activeIndex)
  }, [activeIndex])

  const activeMedia = media[currentSlide]
  const activeReview = reviews?.length > 1 ? reviews.find(
    (review) => review.id === activeMedia?.reviewId
  ) : reviews[0]

  if (!activeReview) return <></>
  return (
    <>
      <ModalDialog
        isOpen={isOpen}
        onRequestDismiss={() => {
          setIsOpen(false)
          trackEvent({
            eventName: 'Ratings Reviews Gallery Engaged',
            properties: { label: 'Image Closed' },
          })
        }}
        fullBleed
        bodyWidth='grow'
      >
        <ResponsiveModalContent
          aria-labelledby='modal dialog general'
          md={{ style: { maxWidth: '60%', maxHeight: '80%' } }}
        >
          <ModalDialogNav>
            <ModalDialogCloseButton accessibleText='Close' />
          </ModalDialogNav>

          <ModalDialogBody>
            <GridContainer>
              <Row>
                <Column span='7' mb='6'>
                  <Carousel
                    slidesToShow={1}
                    accessibleTextForPrevious={translate('previous-slide-label')}
                    accessibleTextForNext={translate('next-slide-label')}
                    infinite={false}
                    currentSlide={currentSlide}
                    afterChange={(index) => {
                      setActiveIndex(index)
                      trackEvent({
                        eventName: "Ratings Reviews Gallery Engaged",
                        properties: { label: "Image Opened" },
                      })
                    }}
                    arrows
                    paddingBottom={4}
                    slidesToScroll={1}
                  >
                    {media.map((image) => {
                      const imageSrc = image.src
                      return (
                        <CarouselSlide key={`${image.id}`}>
                          <BasicResponsiveImage
                            aspectRatio={1}
                            src={
                              imageSrc.startsWith('https')
                                ? imageSrc
                                : 'https:' + imageSrc
                            }
                            alt={image.headline}
                          />
                        </CarouselSlide>
                      )
                    })}
                  </Carousel>
                </Column>
                <Column span='5'>
                  {activeReview && (
                    <ReviewListItem
                      review={activeReview}
                      texts={texts}
                      showImages={false}
                    />
                  )}
                </Column>
              </Row>
            </GridContainer>
          </ModalDialogBody>
        </ResponsiveModalContent>
      </ModalDialog>
    </>
  )
}
