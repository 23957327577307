import { FlexBox, RatingsStars } from '@vp/swan'

interface IStars {
  averageRating: number
  ratedXOutOfYLabel: string
  reviewsCount: number
}
export const Stars: React.FC<IStars> = ({
  averageRating,
  ratedXOutOfYLabel,
  reviewsCount,
}) => {
  return (
    <FlexBox>
      <RatingsStars
        style={{
          zIndex: 0,
          position: "relative"
        }}
        ratingValue={averageRating}
        aria-label={ratedXOutOfYLabel}
        reviews={reviewsCount}
      />{' '}
    </FlexBox>
  )
}
