import { Box } from '@vp/swan'
import { ReviewHistogram } from '../../types'
import { HistogramItem } from './HistogramItem'

interface IHistogram {
  histogram: ReviewHistogram
  filterReview: (arg0: number) => void
  removeFilter: () => void
  filterByRatingValue: number | null
}
export const Histogram: React.FC<IHistogram> = ({
  histogram,
  filterReview,
  removeFilter,
  filterByRatingValue,
}) => {
  const { bars } = histogram

  return (
    <>
      {Array.isArray(bars) &&
        bars.map((histogramItem) => {
          return (
            <Box style={{ position: 'relative' }} key={histogramItem.rating}>
              <HistogramItem
                histogramItem={histogramItem}
                filterReview={filterReview}
                removeFilter={removeFilter}
                isSelected={
                  histogramItem.filterByRatingValue ===
                  Number(filterByRatingValue)
                }
              />
            </Box>
          )
        })}
    </>
  )
}
