import React, { useCallback, useMemo, useState } from 'react'

import { Box, FlexBox, FluidImage } from '@vp/swan'
import { ImageModal } from '../Modal';
import { trackEvent } from '../../utils/tracking';
import { IReviewImage, ReviewItem } from '../../types';

interface IReviewImagesProps {
  review: ReviewItem
  texts: any
  showImages?: boolean
}

export const ReviewImages: React.FC<IReviewImagesProps> = ({ review, texts }) => {
  const [clickedImageIndex, setClickedImageIndex] = useState<number>(-1)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const reviewImageClicked = useCallback(
    (imageIndex: number) => {
      setClickedImageIndex(imageIndex)
      setIsOpen(true)
      trackEvent({ eventName: "Ratings Reviews Engaged", properties: { label: "Image Opened" } })
    },
    []
  )

  const media = useMemo(() => {
    return review.images.map((image) => ({
      ...image,
      reviewId: review.id ?? 0,
    }))
  }, [review])

  return (
    <>
      <FlexBox paddingTop='4' gap='4'>
        {review?.images?.map((image: IReviewImage, index: number) => (
          <Box
            key={image.src}
            onClick={() => {
              reviewImageClicked(index);
            }}
            maxWidth='100px'
            tabIndex={0}
            role='button'
          >
            <FluidImage
              src={image?.src}
              alt={image?.headline}
              loading='lazy'
              style={{ maxWidth: '100px', cursor: 'pointer', display: 'block' }}
            />
          </Box>
        ))}
      </FlexBox>
      <ImageModal
        isOpen={isOpen}
        reviews={[review]}
        media={media}
        activeIndex={clickedImageIndex}
        setActiveIndex={setClickedImageIndex}
        texts={texts}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
