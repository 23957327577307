import { Row, Divider, Column } from '@vp/swan'
import { ReviewItem } from '../../types'
import { ReviewListItem } from './Item'

interface IReviewList {
  reviews: ReviewItem[]
  texts: Record<string, any>
}
export const ReviewsList: React.FC<IReviewList> = ({ reviews, texts }) => {
  return (
    Array.isArray(reviews) &&
    reviews.map((review) => {
      return (
        <Row key={review.id}>
          <Column span='12'>
            <ReviewListItem review={review} texts={texts} />
            <Divider
              style={{
                width: '100%',
                marginTop: 'var(--swan-sem-space-5)',
                marginBottom: 'var(--swan-sem-space-5)',
              }}
            />
          </Column>
        </Row>
      )
    })
  )
}
