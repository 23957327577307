import { ReviewsService } from '../clients/review'

export const getReviewsWithImages = async (
  mpvId: string,
  locale: string,
  pageSize?: number,
  startFrom?: number
) => {
  const reviewService = ReviewsService(mpvId, locale, 'image-carousel')
  reviewService.setSortBy('MediaSort')
  reviewService.setPageSize(pageSize ?? 10)
  reviewService.setStartFrom(startFrom ?? 0)
  return await reviewService.fetchReviews()
}
