import { Column, GridContainer, Row } from '@vp/swan'
import { ReviewHistogram, ReviewSummary } from '../../types'
import { Histogram } from './Histogram'
import { Stars } from './Stars'
import { Fit } from './Fit'
import WriteAReviewButton from './WriteAReviewButton'

interface ISummary {
  summary: ReviewSummary
  histogram: ReviewHistogram
  filterReview: (arg0: number) => void
  removeFilter: () => void
  filterByRatingValue: number | null
}
export const Summary: React.FC<ISummary> = ({
  summary,
  histogram,
  filterReview,
  removeFilter,
  filterByRatingValue,
}) => {
  const sizeFitFeedbackSizes = summary?.feedback?.fit?.histogram?.bars
  return (
    <>
      <GridContainer mb='8'>
        <Row>
          <Column span='3' mb='6' spanXs={12}>
            <Stars
              averageRating={summary.averageRating}
              ratedXOutOfYLabel={summary.ratedXOutOfYLabel}
              reviewsCount={summary.reviewsCount}
            />
            <WriteAReviewButton />
          </Column>

          <Column span='4' mb='6' spanXs={12}>
            <Histogram
              histogram={histogram}
              filterReview={filterReview}
              removeFilter={removeFilter}
              filterByRatingValue={filterByRatingValue}
            />
          </Column>
          {summary?.feedback &&
            sizeFitFeedbackSizes &&
            sizeFitFeedbackSizes?.length > 0 && (
              <Column span='4' marginLeft="auto" spanXs={12}>
                <Fit feedback={summary.feedback} />
              </Column>
            )}
        </Row>
      </GridContainer>
    </>
  )
}
