import {
  FlexBox,
  Typography,
  StandardForm,
  FormField,
  FormLabel,
  SearchInput,
  Dropdown,
  DropdownOption,
  Row,
  Column,
} from '@vp/swan'
import { SortingOptions } from '../../types'
import { useCallback } from 'react'
import { trackEvent } from '../../utils/tracking'
import { useI18n } from '../../localization/LanguageContext'

const SORTING_LABEL: SortingOptions = {
  HighestRating: 'Highest Rated',
  LowestRating: 'Lowest Rated',
  MostHelpful: 'Most Helpful',
  Newest: 'Most Recent',
  Oldest: 'Oldest',
  MediaSort: 'Images',
}

export const ReviewFilter: React.FC<{
  customerLabel: string
  sortReviews: (arg0: string) => void
  searchReviews: (arg0: string) => void
  sortBy: string
  sortingOptions: SortingOptions
}> = ({
  customerLabel,
  sortReviews,
  searchReviews,
  sortBy,
  sortingOptions,
}) => {
    const { translate } = useI18n()

    const onSortByDropdownChange = useCallback(
      (e: React.FormEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value
        sortReviews(value)
        trackEvent({
          eventName: 'Ratings Reviews Sorted',
          properties: { label: SORTING_LABEL[value] },
        })
      },
      []
    )

    return (
      <Row>
        {/* top part with count and form */}
        <Column span='9' mb='4'>
          <Typography fontSkin='title-section' paddingY='2' as='h3'>
            {customerLabel}
          </Typography>
        </Column>
        <Column span='3' mb='4'>
          <FlexBox gap='4'>
            <StandardForm>
              <FormField>
                <FormLabel visuallyHidden>My SearchInput</FormLabel>
                <SearchInput
                  placeholder={translate('reviews-search')}
                  aria-label={translate('reviews-search') || undefined}
                  accessibleTextForClearButton='Clear'
                  accessibleTextForSearchButton='Search'
                  onChange={(e: any) => searchReviews(e.target.value)}
                />
              </FormField>
            </StandardForm>
            <Dropdown
              onChange={onSortByDropdownChange}
              defaultValue={sortBy}
              aria-label={sortingOptions[sortBy]}
              aria-expanded={false}
              role='combobox'
            >
              {Object.keys(sortingOptions).map((sortingKey) => (
                <DropdownOption key={sortingKey} value={sortingKey}>
                  {sortingOptions[sortingKey]}
                </DropdownOption>
              ))}
            </Dropdown>
          </FlexBox>
        </Column>
      </Row>
    )
  }
