export const hosts = {
  vistaprint: {
    'en-US': 'https://www.vistaprint.com/',
    'es-US': 'https://www.vistaprint.com/es/',
    'en-IE': 'https://www.vistaprint.ie/',
    'pt-PT': 'https://www.vistaprint.pt/',
    'en-IN': 'https://www.vistaprint.in/',
    'es-ES': 'https://www.vistaprint.es/',
    'nl-BE': 'https://www.vistaprint.be/',
    'fr-BE': 'https://www.vistaprint.be/fr/',
    'it-IT': 'https://www.vistaprint.it/',
    'nl-NL': 'https://www.vistaprint.nl/',
    'nb-NO': 'https://www.vistaprint.no/',
    'fi-FI': 'https://www.vistaprint.fi/',
    'sv-SE': 'https://www.vistaprint.se/',
    'en-SG': 'https://www.vistaprint.sg/',
    'da-DK': 'https://www.vistaprint.dk/',
    'en-AU': 'https://www.vistaprint.com.au/',
    'en-NZ': 'https://www.vistaprint.co.nz/',
    'en-GB': 'https://www.vistaprint.co.uk/',
    'de-DE': 'https://www.vistaprint.de/',
    'de-CH': 'https://www.vistaprint.ch/',
    'fr-CH': 'https://www.vistaprint.ch/fr/',
    'it-CH': 'https://www.vistaprint.ch/it/',
    'de-AT': 'https://www.vistaprint.at/',
    'fr-FR': 'https://www.vistaprint.fr/',
    'en-CA': 'https://www.vistaprint.ca/',
    'fr-CA': 'https://www.vistaprint.ca/fr/'
  }
}
