export class ResponseHandler<T> {
  private response: Response

  constructor (response: Response) {
    this.response = response
  }

  async process (): Promise<SuccessResponse<T> | ErrorResponse> {
    try {
      const data = await this.response.json() // Parse JSON response

      if (!this.response.ok) {
        throw new ErrorResponse(
          'API request failed',
          this.response.status,
          data
        )
      }

      return new SuccessResponse<T>(
        data as T,
        'Request successful',
        this.response.status
      )
    } catch (error) {
      if (error instanceof ErrorResponse) {
        return error
      }

      return new ErrorResponse('Unexpected error', 500)
    }
  }
}

export class ErrorResponse extends Error {
  status: number
  details: any | null
  success: boolean = false

  constructor (message: string, status: number, details: any = null) {
    super(message)
    this.name = 'ErrorResponse'
    this.status = status
    this.details = details
  }
}

export class SuccessResponse<T> {
  success: boolean = true
  message: string
  status: number
  data: T

  constructor (data: T, message = 'Success', status = 200) {
    this.message = message
    this.status = status
    this.data = data
  }
}
