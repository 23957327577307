import {
  Column,
  FlexBox,
  GridContainer,
  ScreenClassProvider,
  Row,
  Spinner,
  SWAN_STYLE_KEY_MAP,
  Typography,
  Divider,
  responsive,
  BoundedContent,
} from '@vp/swan'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { Summary } from './Summary'
import { ReviewFilter } from './Filter'
import { ReviewsList } from './List'
import { PaginationContainer } from './pagination'
import { ReviewsService } from '../clients/review'
import { useEffect, useRef, useState } from 'react'
import { ImageCarousel } from './ImageCarousel'
import { FragmentProps, Locale, ReviewData } from '../types'
import { I18nProvider } from '../localization/LanguageContext'

let debounceTimer: NodeJS.Timeout
const ResponsiveFlexBox = responsive(FlexBox)

export const Fragment = ({ locale, mpvId, reviews }: FragmentProps) => {
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.ratingsStars,
    SWAN_STYLE_KEY_MAP.progressBar,
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.pagination,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.progressiveImage,
    SWAN_STYLE_KEY_MAP.spinner,
  ])
  const logger = useLogger()
  const userContext = useUserContext()

  logger.log('Rendering fragment for a locale', userContext.locale)

  const reviewsService = ReviewsService(
    mpvId ?? userContext.productId,
    locale ?? userContext.locale
  ) // do not depend on ssr here

  const [updatedReviews, setUpdatedReviews] = useState<ReviewData>()
  const [isLoading, setIsLoading] = useState(false)
  const [filterByRatingValue, setFilterByRatingValue] = useState<number | null>(
    null
  )
  const [summary, setSummary] = useState(reviews?.summary)
  const [histogram, setHistogram] = useState(reviews?.histogram)
  const reviewsHeaderRef = useRef<HTMLElement | null>(null);

  const pageSize = 5

  useEffect(() => {
    reviewsService.setPageSize(pageSize)
    reviewsService.setSortBy('Newest')
    reviewsService.setStartFrom(0)

    // fallback if initial load fails
    if (!reviews) {
      fetchUpdatedReviews()
    }
  }, [])

  const filterReviews = (ratingValue: number) => {
    reviewsService.setFilterByRatingValue(ratingValue)
    fetchUpdatedReviews()
    setFilterByRatingValue(ratingValue)
    scrollToReviewsHeader();
  }
  const removeFilter = () => {
    reviewsService.setFilterByRatingValue(null)
    fetchUpdatedReviews()
    setFilterByRatingValue(null)
  }
  const sortReviews = (sortBy: string) => {
    reviewsService.setSortBy(sortBy)
    reviewsService.setStartFrom(0)
    fetchUpdatedReviews()
    scrollToReviewsHeader();
  }
  const searchReviews = (searchText: string) => {
    reviewsService.setSearchText(searchText !== '' ? searchText : null)

    debounceTimer && clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      fetchUpdatedReviews()
    }, 500) // Adjust delay as needed
  }
  const selectPage = (page: number) => {
    reviewsService.setStartFrom((page - 1) * pageSize)
    fetchUpdatedReviews()
    scrollToReviewsHeader();
  }
  const fetchUpdatedReviews = async () => {
    setIsLoading(true)
    const updatedData = await reviewsService.fetchReviews()
    if (updatedData) {
      setUpdatedReviews(updatedData)
      setIsLoading(false)

      if (
        (!summary || !histogram) &&
        updatedData.summary &&
        updatedData.histogram
      ) {
        setSummary(updatedData.summary)
        setHistogram(updatedData.histogram)
      }
    }
  }
  const scrollToReviewsHeader = () => {
    if (reviewsHeaderRef.current) {
      reviewsHeaderRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const {
    texts,
    reviews: reviewsList,
    pagination,
    sortBy,
  } = updatedReviews ?? reviews ?? {}

  if (
    !summary ||
    !histogram ||
    !sortBy ||
    !pagination ||
    !texts ||
    !reviewsList
  ) {
    return <></>
  }
  return (
    <I18nProvider defaultLocale={locale as Locale}>
      <ScreenClassProvider>
        <BoundedContent my='between-sections'>
          <GridContainer id='reviews-container'>
            <Row>
              <Column span='12'>
                <Typography fontSkin='title-section' mb='between-subsections' as='h2'>
                  {texts.snapshotLabel}
                </Typography>
              </Column>
            </Row>
            <Summary
              histogram={histogram}
              summary={summary}
              filterReview={filterReviews}
              removeFilter={removeFilter}
              filterByRatingValue={filterByRatingValue}
            />
            <Row>
              <Column span='12'>
                <Divider
                  style={{
                    width: '100%',
                    marginTop: 'var(--swan-sem-space-between-subsections)',
                    marginBottom: 'var(--swan-sem-space-between-subsections)',
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column span='12'>
                <ImageCarousel />
              </Column>
            </Row>
            <Row>
              <Column span='12'>
                <Divider
                  style={{
                    width: '100%',
                    marginTop: 'var(--swan-sem-space-between-subsections)',
                    marginBottom: 'var(--swan-sem-space-between-subsections)',
                  }}
                />
              </Column>
            </Row>
          </GridContainer>
          <GridContainer ref={reviewsHeaderRef}>
            <ReviewFilter
              customerLabel={summary.reviewedByNCustomersLabel}
              sortReviews={sortReviews}
              searchReviews={searchReviews}
              sortBy={sortBy}
              sortingOptions={texts.sorting}
            />
            <Row>
              <Column span='12'>
                {isLoading && (
                  <FlexBox justifyContent='center' paddingY='8'>
                    <Spinner size='standard' accessibleText='Loading...' />
                  </FlexBox>
                )}
              </Column>
            </Row>
            <ReviewsList reviews={reviewsList} texts={texts} />
          </GridContainer>
          <GridContainer>
            <Row>
              <Column span='12'>
                <ResponsiveFlexBox
                  alignItems='center'
                  xs={{ flexDirection: 'column', justifyContent: 'center' }}
                  sm={{ flexDirection: 'row', justifyContent: 'space-between' }}
                  gap={4}
                >
                  <Typography fontSkin='footnote'>
                    {texts.displayingReviewsLabel}&nbsp;
                    {pagination.currentReviewShowing}
                  </Typography>
                  <PaginationContainer
                    pagination={pagination}
                    selectPage={selectPage}
                  />
                </ResponsiveFlexBox>
              </Column>
            </Row>
          </GridContainer>
        </BoundedContent>
      </ScreenClassProvider>
    </I18nProvider>
  )
}
