import { Typography, FlexBox, Box, Progress } from '@vp/swan'
import { Feedback } from '../../types'
import { useMemo } from 'react'
import { useI18n } from '../../localization/LanguageContext'

interface IFit {
  feedback: Feedback
}

export const Fit: React.FC<IFit> = ({ feedback }) => {
  const sizes = feedback?.fit?.histogram?.bars
  const { translate } = useI18n()

  const isExtremeEnd = (index: number) =>
    index === 0 || index === sizes!.length - 1
  const indexForChosenFit = useMemo(() => {
    const highestCount = Math.max(...sizes!.map((size) => size.count))

    const sizeAndIndexWithChosenFit: { delta: number; index: number } = {
      delta: Number.MAX_SAFE_INTEGER,
      index: -1,
    }
    const midIndex = Math.floor(sizes!.length / 2)

    sizes!.forEach((size, index) => {
      if (highestCount === size.count) {
        const absCountDiff = Math.abs(midIndex - index)
        if (absCountDiff <= sizeAndIndexWithChosenFit.delta) {
          sizeAndIndexWithChosenFit.delta = absCountDiff
          sizeAndIndexWithChosenFit.index = index
        }
      }
    })
    return sizeAndIndexWithChosenFit.index
  }, [sizes])

  const isIndexOfChosenFit = (index: number) => indexForChosenFit === index

  return (
    <>
      <Typography fontSkin='body-standard-bold'>
        {translate('size-feedback-label')}
      </Typography>
      <FlexBox style={{ width: '100%' }} gap='1' paddingY='4'>
        {sizes?.map((size, index) => {
          return (
            <Box style={{ flex: 1 }} key={index}>
              <Progress
                id='sample-progress-bar'
                aria-labelledby='progress-bar-description'
                value={isIndexOfChosenFit(index) ? 100 : 0}
              />
              <FlexBox justifyContent='space-between'>
                {(isIndexOfChosenFit(index) || isExtremeEnd(index)) && (
                  <Typography
                    fontSkin={isIndexOfChosenFit(index) ? "body-bold" : "body-standard"}
                    p="1"
                    role="heading"
                    aria-level={4}
                  >
                    {size.label}
                  </Typography>
                )}
              </FlexBox>
            </Box>
          )
        })}
      </FlexBox>
    </>
  )
}
