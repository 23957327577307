import { FlexBox, RatingsStars, Typography, Icon, Card } from '@vp/swan'
import { ReviewItem } from '../../types'
import { ReviewImages } from '../Reviews/ReviewImages'

interface IReviewItem {
  review: ReviewItem
  texts: any
  showImages?: boolean
}
export const ReviewListItem: React.FC<IReviewItem> = ({ review, texts, showImages = true }) => {
  return (
    <FlexBox flexDirection='column'>
      {/* review rating */}
      <RatingsStars
        style={{
          zIndex: 0,
          position: "relative"
        }}
        ratingValue={review.rating}
        aria-label={review.ratedXOutOfY}
      />
      {/* review title */}
      <Typography fontSkin='title-item'>{review.header}</Typography>

      {/* User info */}
      <FlexBox gap='2' paddingY='4'>
        <Typography fontSkin='body-small' textColor='subtle'>
          {review.authorSubmissionDate} | {review.nickname}
        </Typography>
        {review.isVerifiedBuyer && (
          <FlexBox gap='2' alignItems='center'>
            <Icon
              iconType='guaranteedSatisfaction'
              size='16p'
              skin='subtle'
              objectFit='cover'
            />
            <Typography fontSkin='body-small' textColor='subtle'>
              {texts?.disclosureCodeLabels?.verified}
            </Typography>
          </FlexBox>
        )}
      </FlexBox>
      {/* review texty body */}
      <Typography fontSkin='body-standard' paddingY='2' marginBottom='4'>
        {review.comments}
      </Typography>
      {/* care response */}
      {review.merchantResponse && (
        <Card bordered>
          <Typography textColor='subtle' fontSkin='body-standard-bold'>
            {texts.merchantResponseLabel}
          </Typography>
          <Typography fontSkin='body-standard-bold' paddingY='4'>
            {review.merchantResponse}
          </Typography>
        </Card>
      )}
      {review.images && showImages && <ReviewImages review={review} texts={texts}/>}
    </FlexBox>
  )
}
