import { ErrorResponse, ResponseHandler, SuccessResponse } from './response'

export class APIClient {
  private maxRetries: number = 5
  private retryDelay: number = 1000 // 1 second

  async fetchWithRetry<T>(
    url: string
  ): Promise<SuccessResponse<T> | ErrorResponse> {
    for (let attempt = 1; attempt <= this.maxRetries; attempt++) {
      try {
        const response = await fetch(url)
        const handler = new ResponseHandler<T>(response)
        return await handler.process() // Use ResponseHandler for structured responses
      } catch (error) {
        if (attempt === this.maxRetries) {
          return new ErrorResponse(
            `API request failed after ${this.maxRetries} attempts`,
            500,
            (error as Error).message
          )
        }
        await new Promise((resolve) =>
          setTimeout(resolve, this.retryDelay * attempt)
        ) // Exponential backoff
      }
    }

    return new ErrorResponse('Unexpected error', 500)
  }
}
