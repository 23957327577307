import { getTracking } from '@vp/tracking'
import { TrackingConfig } from '../types'

export const trackEvent = (
  trackingConfig: TrackingConfig,
  event?: React.MouseEvent<HTMLElement, MouseEvent>,
  href?: string
) => {
  if (event && href) {
  }
  const { eventName, properties } = trackingConfig
  const tracking = getTracking()

  if (tracking && eventName) {
    tracking.track(eventName, properties)
  } else {
    const options: AddEventListenerOptions = { once: true }
    window.addEventListener(
      'trackingReady',
      () => {
        const tracking = getTracking()
        tracking?.track(eventName, properties)
      },
      options
    )
  }
}
