export type ReviewSummary = {
  reviewsCount: number
  ratedXOutOfYLabel: string
  averageRating: number
  reviewsCountLabel: string
  reviewedByNCustomersLabel: string
  stars: [number, number, number, number, number] // Tuple with exactly 5 numbers
  feedback?: Feedback
}

export type Size = {
  count: number
  label: string
}

export type Feedback = {
  fit?: {
    histogram?: {
      bars: Size[]
    }
  }
}

// Define a type for each bar
export type ReviewHistogramItem = {
  tabIndex: number
  label: string
  filterByRatingValue: number
  width: string
  rating: number
  tooltipLabel: string
}

// Define the overall ratings distribution type.
// Here, bars is defined as a tuple of exactly 5 RatingBar objects.
export type ReviewHistogram = {
  legend: string
  bars: ReviewHistogramItem[]
}

export type IReviewImage = {
  id: number;
  headline: string;
  src: string;
  caption?: string;
};

export type ReviewItem = {
  id: number;
  rating: number;
  ratedXOutOfY: string;
  stars: number[];
  header: string;
  authorSubmissionDate: string;
  nickname: string;
  authorLocation: string;
  comments: string;
  merchantResponse?: string;
  images: IReviewImage[];
  isVerifiedBuyer: boolean;
  disclosureCode: string;
}

export interface ReviewsPagination {
  currentReviewShowing: string
  next: string
  previous: string
  paginationLabel: string
  paginationInputLabel: string
  totalResults: number
  pagesTotal: number
  pageSize: number
  currentPageNumber: number
}

export type SortingOptions = Record<string, string>

export enum Locale {
  deDE = 'de-DE',
  esES = 'es-ES',
  enUS = 'en-US',
  enGB = 'en-GB',
  fiFI = 'fi-FI',
  nlNL = 'nl-NL',
  itIT = 'it-IT',
  svSE = 'sv-SE',
  nbNO = 'nb-NO',
  ptPT = 'pt-PT',
  daDK = 'da-DK',
  frCA = 'fr-CA',
  esUS = 'es-US',
  enCA = 'en-CA',
  nlBE = 'nl-BE',
  enIE = 'en-IE',
  frBE = 'fr-BE',
  frFR = 'fr-FR',
  frCH = 'fr-CH',
  deAT = 'de-AT',
  deCH = 'de-CH',
  itCH = 'it-CH',
  enAU = 'en-AU',
  enNZ = 'en-NZ',
  enSG = 'en-SG',
  enIN = 'en-IN',
}

export interface ReviewData {
  tenant: string
  locale: string
  mpvId: string
  sortBy: string
  summary: ReviewSummary
  texts: Record<string, any>
  pagination: ReviewsPagination
  reviews: ReviewItem[]
  histogram: ReviewHistogram
}

export type FragmentProps = {
  locale: string
  mpvId: string
  tenant: string
  reviews?: ReviewData
}

export type TrackingConfig = {
  eventName: string;
  properties: {
    [k: string]: string;
  };
  linkAttributes?: {
    [k: string]: string | number;
  }
}
