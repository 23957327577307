import React, { useMemo } from 'react'
import { Button, Link } from '@vp/swan'
import { hosts } from '../../clients/constants/hosts'
import { useUserContext } from '@vp/ubik-context'
import { trackEvent } from '../../utils/tracking'
import { useI18n } from '../../localization/LanguageContext'

interface WriteAReviewButtonProps {
  isDevelopment?: boolean
  orderId?: string
  lineItemId?: string
}

export const WriteAReviewButton: React.FC<WriteAReviewButtonProps> = ({
  isDevelopment,
  orderId,
  lineItemId,
}) => {
  const { locale, productId } = useUserContext()
  const link = useMemo(
    () =>
      getWriteAReviewPageLink(
        locale,
        productId!,
        isDevelopment,
        orderId,
        lineItemId
      ),
    [productId, orderId, lineItemId, locale, isDevelopment]
  )
  const { translate } = useI18n()

  const onClickWARButton = () => {
    trackEvent({
      eventName: 'Ratings Reviews Engaged',
      properties: { label: 'Write a Review Clicked' },
    })
  }

  return (
    <Button
      skin='secondary'
      mt='6'
      render={(props: { className: any; children: any }) => (
        <Link onClick={onClickWARButton} to={link} className={props.className}>
          {props.children}
        </Link>
      )}
    >
      <span>{translate('write-a-review-label')}</span>
    </Button>
  )
}

export default WriteAReviewButton

const getWriteAReviewPageLink = (
  locale: string,
  mpvId: string,
  isDevelopment?: boolean,
  orderId?: string,
  orderItemId?: string
) => {
  const sandboxWARHost =
    'https://www.vistaprint.com/static/merch/write-a-review-page-vistaprint-eu.sandbox/'

  const host = (hosts['vistaprint'] as any)[getLocaleForProduction(locale)]
  const productionWARHost = host || 'https://www.vistaprint.com/'

  let link = isDevelopment
    ? `${sandboxWARHost}${getLocaleForSandbox(
        locale
      )}/wr/index.html?mpv_id=${mpvId}`
    : `${productionWARHost}wr?mpv_id=${mpvId}`

  if (orderId && orderItemId) {
    link = `${link}&order_id=${orderId}&order_item_id=${orderItemId}`
  }

  return link
}

const getLocaleForSandbox = (locale: string) => {
  return locale?.replace('_', '-').toLowerCase()
}

const getLocaleForProduction = (locale: string) => {
  return locale?.replace('_', '-')
}
