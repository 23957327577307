import { ReviewData } from '../types'
import { APIClient } from './api'
import { CacheManager } from './cache'
import { SuccessResponse } from './response'

class ReviewsServiceClass {
  private static instances: Map<string, ReviewsServiceClass> = new Map()
  private baseUrl: string =
    'https://rating-reviews.prod.merch.vpsvc.com/v1/reviews/vistaprint'

  private filterByRatingValue?: number | null
  private pageSize?: number
  private sortBy?: string
  private startFrom?: number
  private searchText?: string | null
  private apiClient: APIClient
  private cacheManager: CacheManager<ReviewData>

  private constructor (private mpvId: string, private locale: string) {
    this.apiClient = new APIClient()
    this.cacheManager = new CacheManager()
  }

  static getInstance (
    mpvId: string,
    locale: string,
    id: string = 'default'
  ): ReviewsServiceClass {
    const key = `${mpvId}-${locale}-${id}`
    if (!this.instances.has(key)) {
      this.instances.set(key, new ReviewsServiceClass(mpvId, locale))
    }
    return this.instances.get(key)!
  }

  setFilterByRatingValue (value: number | null): void {
    this.filterByRatingValue = value
  }

  setPageSize (size: number): void {
    this.pageSize = size
  }

  setSearchText (searchText: string | null): void {
    this.searchText = searchText
  }

  setSortBy (sort: string): void {
    this.sortBy = sort
  }

  setStartFrom (start: number): void {
    this.startFrom = start
  }

  private validateParams (): void {
    if (
      this.pageSize === undefined ||
      this.sortBy === undefined ||
      this.startFrom === undefined
    ) {
      throw new Error('Missing review request params')
    }
  }

  private getCacheKey (): string {
    const cacheParts: Array<string | number> = []

    if (this.filterByRatingValue != null) {
      cacheParts.push(this.filterByRatingValue)
    }
    if (this.pageSize != null) cacheParts.push(this.pageSize)
    if (this.sortBy != null) cacheParts.push(this.sortBy)
    if (this.startFrom != null) cacheParts.push(this.startFrom)
    if (this.searchText != null) cacheParts.push(this.searchText)

    return cacheParts.join('-')
  }

  async fetchReviews (): Promise<ReviewData | undefined> {
    this.validateParams()

    const cacheKey = this.getCacheKey()
    const cachedData = this.cacheManager.getCache(cacheKey)

    if (cachedData) {
      return cachedData
    }

    const url = this.buildUrl()

    const response = await this.apiClient.fetchWithRetry<ReviewData>(url)
    if (response instanceof SuccessResponse) {
      this.cacheManager.setCache(cacheKey, response.data)
      return response.data
    }
    console.error('Error fetching data', response)
  }

  private buildUrl () {
    const params: string[] = []

    if (this.filterByRatingValue) {
      params.push(`filterByRatingValue=${this.filterByRatingValue}`)
    }

    if (this.pageSize) {
      params.push(`pageSize=${this.pageSize}`)
    }

    if (this.sortBy) {
      params.push(`sortBy=${this.sortBy}`)
    }

    if (this.startFrom) {
      params.push(`startFrom=${this.startFrom}`)
    }
    if (this.searchText) {
      params.push(`searchText=${this.searchText}`)
    }

    // Join the params into a query string and prepend it to the base URL
    return `${this.baseUrl}/${this.locale}/${this.mpvId}?${params.join('&')}`
  }
}
export const ReviewsService = (
  mpvId: string,
  locale: string,
  id?: string
): ReviewsServiceClass => {
  return ReviewsServiceClass.getInstance(mpvId, locale, id)
}
