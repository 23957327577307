export class CacheManager<T> {
  private cache: Map<string, { data: T; timestamp: number }> = new Map()
  private cacheDuration: number = 60 * 60 * 1000 // 1 hour in milliseconds

  getCache (key: string): T | null {
    const cached = this.cache.get(key)
    if (cached && Date.now() - cached.timestamp < this.cacheDuration) {
      return cached.data
    }
    return null
  }

  setCache (key: string, data: T): void {
    this.cache.set(key, { data, timestamp: Date.now() })
  }
}
